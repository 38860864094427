import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import OpenInNewIcon from '@material-ui/icons/OpenInNew';
export const _frontmatter = {
  "title": "Disbursements by month",
  "includeToc": true,
  "layout": "downloads",
  "description": "Revenue collected from extraction of natural resources on federal and Native American lands is distributed to various legislated funds, local governments, and federal agencies. This process is called disbursement.",
  "tags": ["Data", "Downloads", "Documentation", "Disbursements"]
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const DownloadDataFile = makeShortcode("DownloadDataFile");
const GlossaryTerm = makeShortcode("GlossaryTerm");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><a parentName="p" {...{
        "href": "/downloads/"
      }}>{`Downloads /`}</a></p>

    <h1>{props.pageContext.frontmatter.title}</h1>
    <p>{`Revenue collected from extraction of natural resources on federal and Native American lands is distributed to various legislated funds, local governments, and federal agencies. This process is called disbursement. We have disbursements data by month from October 2014 through the most recent month.`}</p>
    <br />
    <p>{`Download disbursement by month data:`}</p>
    <DownloadDataFile linkType="DownloadCsv" dataSet="monthly_disbursements.csv" mdxType="DownloadDataFile">
  Monthly disbursements
    </DownloadDataFile>
    <DownloadDataFile linkType="DownloadXls" dataSet="monthly_disbursements.xlsx" mdxType="DownloadDataFile">
  Monthly disbursements
    </DownloadDataFile>
    <h2 {...{
      "id": "Scope",
      "style": {
        "position": "relative"
      }
    }}>{`Scope`}</h2>
    <p>{`This dataset includes natural resource disbursements for U.S. federal lands, federal offshore areas, and Native American lands. It does not include privately owned lands or U.S. state lands. Disbursements of revenue from extractive activities on U.S. federal lands occur bi-weekly. `}</p>
    <br />
    <p>{`The dataset is tracked and managed by the Department of the Interior’s Office of Natural Resources Revenue. The data contains disbursement payments by month to state and local governments, designated funds, and the U.S. Treasury. The data is presented by month, from October 2014 through the most recently available month.`}</p>
    <h2 {...{
      "id": "Data-publication",
      "style": {
        "position": "relative"
      }
    }}>{`Data publication`}</h2>
    <p>{`We publish the monthly file when we receive the data for the most recently available month, which is usually 1–2 months prior to the current month.`}</p>
    <h2 {...{
      "id": "Data-dictionary",
      "style": {
        "position": "relative"
      }
    }}>{`Data dictionary`}</h2>
    <h3 {...{
      "id": "Fields-and-definitions",
      "style": {
        "position": "relative"
      }
    }}>{`Fields and definitions`}</h3>
    <p>{`Laws treat revenues from offshore natural resources and onshore natural resources differently. There are set percentages and amounts from each that go to specific funds, local governments, or government agencies. All revenue collected from Native American lands is disbursed back to Native American tribes, nations, or individuals.`}</p>
    <br />
    <p><strong parentName="p">{`Date:`}</strong>{`  The date the disbursement occurred.`}</p>
    <br />
    <p><strong parentName="p">{`Fund Type:`}</strong>{`  The type of recipient receiving the disbursement.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`BLM-Nat Petrol Reserve-2:`}</strong>{`  Funds from oil and gas leases directed to the National Petroleum Reserve in Alaska.`}</li>
      <li parentName="ul"><strong parentName="li">{`Bureau of Land Management:`}</strong>{`  Certain funds are directed back to the federal agencies that administer these lands and waters. These funds help cover the agencies’ operational costs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Bureau of Ocean Energy Management:`}</strong>{`  Certain funds are directed back to the federal agencies that administer these lands and waters. These funds help cover the agencies’ operational costs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Bureau of Safety & Environmental Enforcement:`}</strong>{`  Certain funds are directed back to the federal agencies that administer these lands and waters. These funds help cover the agencies’ operational costs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Fish & Wildlife:`}</strong>{`  Certain funds are directed back to the federal agencies that administer these lands and waters. These funds help cover the agencies’ operational costs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Forest Service:`}</strong>{`  Certain funds are directed back to the federal agencies that administer these lands and waters. These funds help cover the agencies’ operational costs.`}</li>
      <li parentName="ul"><strong parentName="li">{`Land & Water Conservation Fund (LWCF):`}</strong>{`  This fund provides matching grants to states and local governments. Using these grands, governments buy and develop public outdoor recreation areas across the 50 states.`}</li>
      <li parentName="ul"><strong parentName="li">{`Land and Water Conservation Fund GOMESA:`}</strong>{`  The Gulf of Mexico Energy Security Act (GOMESA) of 2006 specifies that 12.5% of revenues from certain gulf leases be directed to the LWCF stateside program. States can receive up to $125 million a year in funding. This portion of LWCF funds is not subject to the congressional appropriation process. Otherwise, they are treated similarly to regular LWCF funds.`}</li>
      <li parentName="ul"><strong parentName="li">{`Lease Process Improvement (BLM):`}</strong>{`  This fund was established to support the coordination and processing of oil and gas leases on federal onshore land.`}</li>
      <li parentName="ul"><strong parentName="li">{`Native American Tribes & Allottees:`}</strong>{`  ONRR disburses 100% of revenue collected from resource extraction on Native American lands back to Native American  tribes, nations, and individual Indian landowners.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reclamation Fund:`}</strong>{`  Established by Congress in 1902. Funds the development of irrigation projects on arid and semiarid lands of the 17 western states.`}</li>
      <li parentName="ul"><strong parentName="li">{`State:`}</strong>{`  Funds disbursed to states fall under the jurisdiction of each state. Each state determines how the funds will be used.`}</li>
      <li parentName="ul"><strong parentName="li">{`State 8(g):`}</strong>{`  27% of revenue from leases in the 8(g) Zone are shared with states. The 8(g) Zone is the first three nautical miles of the Outer Continental Shelf.`}</li>
      <li parentName="ul"><strong parentName="li">{`State GOMESA:`}</strong>{`  37.5% of revenue from certain leases in the Gulf of Mexico are shared with Alabama, Louisiana, Mississippi, and Texas.`}</li>
      <li parentName="ul"><strong parentName="li">{`U.S. Treasury`}</strong>{`  Funds disbursed to the Treasury go to the General Fund, which is the federal government’s basic operating fund. The General Fund pays for roughly two-thirds of all federal expenditures, including the U.S. military, national parks, and schools.`}</li>
      <li parentName="ul"><strong parentName="li">{`U.S. Treasury GOMESA:`}</strong>{`  The Gulf of Mexico Energy Security Act (GOMESA) of 2006 specifies that 50% of revenues from certain gulf leases be directed to the U.S. Treasury.`}</li>
    </ul>
    <p><strong parentName="p">{`Land Category:`}</strong>{`  Whether the origin of the revenue being disbursed is offshore or onshore.`}</p>
    <br />
    <p><strong parentName="p">{`Disbursement Type:`}</strong>{`  Disbursement types are guided by revenue-sharing legislation and agreements. For example, `}<GlossaryTerm mdxType="GlossaryTerm">{`GOMESA`}</GlossaryTerm>{` and `}<GlossaryTerm mdxType="GlossaryTerm">{`8(g)`}</GlossaryTerm>{` direct portions of offshore revenue to states, local governments, and designated funds. Disbursements from the production of geothermal energy is also shared with county governments.`}</p>
    <br />
    <p><strong parentName="p">{`State:`}</strong>{` The state that received the disbursement in accordance with revenue-sharing legislation.`}</p>
    <br />
    <p><strong parentName="p">{`County:`}</strong>{` The county, parish, or borough that received the disbursement in accordance with revenue-sharing legislation.`}</p>
    <br />
    <p><strong parentName="p">{`Category (dollar):`}</strong>{` Includes the phases of lease payments.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bonuses:`}</strong>{`  The amount paid by the highest bidder for a natural resource lease.`}</li>
      <li parentName="ul"><strong parentName="li">{`Rents:`}</strong>{`  The annual payment for leasing lands or waters before production starts.`}</li>
      <li parentName="ul"><strong parentName="li">{`Royalties:`}</strong>{`  Payments for extracted natural resources, determined by a percentage of the resources’ production value.`}</li>
      <li parentName="ul"><strong parentName="li">{`Other Distributions:`}</strong>{`  Revenues from fees and civil penalties.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sequestration:`}</strong>{`  This represents `}<a parentName="li" {...{
          "href": "https://www.cbo.gov/topics/budget/sequestration"
        }}>{`statutory spending limits `}<OpenInNewIcon style={{
            fontSize: 20
          }} mdxType="OpenInNewIcon" /></a>{` which are withheld from certain government programs. Some program funds are returned in following fiscal year.`}</li>
    </ul>
    <p><strong parentName="p">{`Commodity:`}</strong>{` Refers to the commodity that generated the revenue. For oil and gas, the commodity listed depends on the phase of production. Since it's unknown whether a lease will produce oil, gas, or both, the two commodities are combined in the bonus and rent phases and listed as "Oil & Gas (pre-production)."`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Clay`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Coal`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Copper`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Gas`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Geothermal`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Gilsonite`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Gold`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Hardrock`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Lead/Zinc`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Limestone`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Oil`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Oil & Gas (pre-production)`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Oil Shale`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Phosphate`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Potassium`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Quartz`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sodium`}</strong></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Wind`}</strong></p>
      </li>
    </ul>
    <p><strong parentName="p">{`Disbursement:`}</strong>{`  The amount disbursed.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      